import React from 'react';

import PageLayout from '../components/PageLayout';
import SEO from '../components/seo';

class NotFoundPage extends React.Component {
  render() {
    return (
      <PageLayout>
        <SEO title="Success!" />
        <div>
          <h1>Success!</h1>
          <p>Thank you for contacting me!</p>
        </div>
      </PageLayout>
    );
  }
}

export default NotFoundPage;
